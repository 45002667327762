const categories: Peeeps.Category[] = [
  "food",
  "shopping",
  "sightseeing",
  "experience",
  "culture",
  "event",
  "stay",
  "job",
  "medical",
  "education",
  "family",
  "sports",
  "spiritual",
  "hobby",
  "outdoor",
];

const en: Record<Peeeps.Category | "all", string> = {
  all: "ALL",
  food: "FOOD",
  shopping: "SHOPPING",
  sightseeing: "SIGHTSEEING",
  experience: "EXPERIENCE",
  culture: "CULTURE",
  event: "EVENT",
  stay: "STAY",
  job: "JOB",
  medical: "MEDICAL",
  education: "EDUCATION",
  family: "FAMILY",
  sports: "SPORTS",
  spiritual: "SPIRITUAL",
  hobby: "HOBBY",
  outdoor: "OUTDOOR",
};

const ja: Record<Peeeps.Category | "all", string> = {
  all: "全て",
  food: "グルメ・料理",
  shopping: "買い物",
  sightseeing: "観光",
  experience: "体験・アクティビティ",
  culture: "日本文化",
  event: "イベント",
  stay: "住宅・宿泊",
  job: "仕事",
  medical: "医療",
  education: "教育",
  family: "ファミリー",
  sports: "スポーツ",
  spiritual: "セラピー",
  hobby: "趣味",
  outdoor: "アウトドア・自然",
};

const zh: Record<Peeeps.Category | "all", string> = {
  all: "整",
  food: "餐饮",
  shopping: "购物",
  sightseeing: "观光",
  experience: "经验",
  culture: "日本文化",
  event: "事件",
  stay: "住",
  job: "工作",
  medical: "医",
  education: "教育",
  family: "家庭",
  sports: "運動的",
  spiritual: "治療",
  hobby: "愛好",
  outdoor: "戶外/自然",
};

const categoryItems = categories.map((category) => {
  return {
    value: category,
    en: en[category],
    ja: ja[category],
    "zh-Hans": zh[category],
  };
});

const categoryEnName = (value: Peeeps.Category) => {
  return en[value];
};

const categoryJaName = (value: Peeeps.Category) => {
  return ja[value];
};

const categoryZhName = (value: Peeeps.Category) => {
  return zh[value];
};

export {
  categories,
  categoryItems,
  categoryEnName,
  categoryJaName,
  categoryZhName,
};
