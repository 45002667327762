
import Vue from "vue";
import { categoryItems } from "@/categories";
import { Category } from "~~/types";
export default Vue.extend({
  data() {
    return {
      categoryItems,
    };
  },
  methods: {
    toCategory(name: string) {
      this.$router.push(
        this.localePath({
          name: "lists-all",
          params: { 0: name },
        })
      );
    },
    translateCategory(object: {
      value?: Category;
      en: string;
      ja: string;
      "zh-Hans"?: string;
      ""?: string;
    }) {
      if (this.$i18n.locale === "en") {
        return `${object.en}`;
      } else if (this.$i18n.locale === "ja") {
        return `${object.ja}`;
      } else if (this.$i18n.locale === "zh-Hans") {
        return `${object["zh-Hans"]}`;
      } else ""!;
    },
  },
});
